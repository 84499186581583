import error_con_src from './error_con.svg';
import popup_email_src from './find-password-popup.png';
import ic_zigzag_src from './ic_zigzag.svg';
import kakao_icon_src from './kakao-icon.png';
import connect_kakao_zigzag_src_src from './kakao-zigzag-connect.png';
import kakaotalk_icon_src from './kakaotalk-icon.png';
import protect_icon_src from './protect-icon.png';
import warning_src from './warning.svg';
import zigzg_join_src from './zigzag_join.png';
import zigzag_toss_logo_src from './zigzag_x_toss.png';
import zigzag_logo_src from './zigzag-bi.png';
export const popup_email = popup_email_src;
export const kakao_icon = kakao_icon_src;
export const connect_kakao_zigzag_src = connect_kakao_zigzag_src_src;
export const zigzag_logo = zigzag_logo_src;
export const kakaotalk_icon = kakaotalk_icon_src;
export const protect_icon = protect_icon_src;
export const zigzg_join = zigzg_join_src;
export const error_con = error_con_src;
export const warning = warning_src;
export const ic_zigzag = ic_zigzag_src;
export const zigzag_toss_logo = zigzag_toss_logo_src;
